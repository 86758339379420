import React from "react";
import "./FullScreenImage.scss";

const FullScreenImage = ({ imageUrl, onClose }) => {
  return (
    <div className="full-screen-image-overlay" onClick={onClose}>
      <div className="full-screen-image-container">
        <img src={imageUrl} alt="Full Screen" />
      </div>
    </div>
  );
};

export default FullScreenImage;
