import React, { useState, createContext, useContext } from "react";
import FullScreenImage from "../components/FullScreenImage/FullScreenImage";

const FullScreenContext = createContext();

export const FullScreenProvider = ({ children }) => {
  const [fullScreenImage, setFullScreenImage] = useState(null);

  const openFullScreen = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  const closeFullScreen = () => {
    setFullScreenImage(null);
  };

  return (
    <FullScreenContext.Provider value={{ openFullScreen, closeFullScreen }}>
      {children}
      {fullScreenImage && (
        <FullScreenImage imageUrl={fullScreenImage} onClose={closeFullScreen} />
      )}
    </FullScreenContext.Provider>
  );
};

export const useFullScreen = () => {
  return useContext(FullScreenContext);
};
